// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: black;
    color: #00ff00; /* Green text */
    font-family: 'Courier New', Courier, monospace; /* Old school font */
    margin: 0;
    padding: 20px;
    line-height: 1.6;
}

h1, h2 {
    text-align: center;
    text-shadow: 0 0 5px #00ff00;
}

p {
    margin: 20px 0;
}

.container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    border: 1px solid #00ff00;
    box-shadow: 0 0 10px #00ff00;
}
.email {
    color: #00ff00;
}`, "",{"version":3,"sources":["webpack://./src/Blog.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,cAAc,EAAE,eAAe;IAC/B,8CAA8C,EAAE,oBAAoB;IACpE,SAAS;IACT,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,4BAA4B;AAChC;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,4BAA4B;AAChC;AACA;IACI,cAAc;AAClB","sourcesContent":["body {\n    background-color: black;\n    color: #00ff00; /* Green text */\n    font-family: 'Courier New', Courier, monospace; /* Old school font */\n    margin: 0;\n    padding: 20px;\n    line-height: 1.6;\n}\n\nh1, h2 {\n    text-align: center;\n    text-shadow: 0 0 5px #00ff00;\n}\n\np {\n    margin: 20px 0;\n}\n\n.container {\n    max-width: 800px;\n    margin: auto;\n    padding: 20px;\n    border: 1px solid #00ff00;\n    box-shadow: 0 0 10px #00ff00;\n}\n.email {\n    color: #00ff00;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
