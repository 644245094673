import React from 'react';
import './Blog.css'; // Import the CSS file for styling

const Blog = () => {
    return (
        <div className="container" style={{ fontSize: '18px' }}>
            <h1>Welcome!</h1>
            <h2>Hi, I’m Balázs Barna</h2>
            <p>
                I’m an engineer from Budapest, Hungary, passionate about solving complex problems through technology. My journey into tech began early, assembling PCs at age five. Later, inspired by my father—a software engineer—I learned to code in Assembly, C, C++, and C#. Since 2012, I’ve specialized in Java, building systems that handle billions of dollars in transactions.
            </p>
            <p>
                Outside of work, I grew up with two passions: programming and kickboxing 🥊. I’ve won multiple titles, including two world cups. Kickboxing taught me an invaluable lesson: life’s challenges are inevitable, and while you can’t control the outcome, you can control your willingness to face them.
            </p>
            <h2>Rewriting the Systems That Power Finance</h2>
            <p>
                I began my career at Morgan Stanley through their graduate program—one of the few opportunities in Hungary at the time to work abroad. It allowed me to gain invaluable international experience in New York and London while working on the firm’s detailed post-trade sub-ledger system. At Morgan Stanley, I learned what it means to be professional, diligent, and to work on systems that move billions of dollars every day.
            </p>
            <p>
                In 2014, I joined MSCI, where I was responsible for rewriting their Corporate Events System, a critical component for calculating global financial indices relied on by institutional investors.
            </p>
            <h2>Joining Wise</h2>
            <p>
                In 2016, I joined TransferWise (now Wise), a magical company with an incredibly efficient, down-to-earth culture. Wise allowed me to see the world, work with exceptionally talented people, and grow personally and professionally—it truly gave me wings.
            </p>
            <p>
                When I joined, Wise had fewer than 500 people. I started by working on payment processing and orchestration systems, including leading a rewrite of how Wise routes payments. One of my most impactful contributions was building a rule engine to generate transfer plans, deciding which payment partners to use and ensuring liquidity was available on time. This improved estimation accuracy from 60% to 94%, establishing clear SLAs for internal teams and ensuring we delivered on customer promises. Its importance was clear: when a transfer is delayed, customer contact rates increase tenfold.
            </p>
            <p>
                In 2017, I led Wise’s European Expansion teams, launching the service in 26 countries and 14 currencies. A highlight was SEPA Instant integration 🇪🇺, which drastically improved transfer speeds.
            </p>
            <p>
                I also initiated and led the Hungarian Central Bank integration 🇭🇺—a first-of-its-kind achievement for Wise and fintechs in the European Union. At a conference, I built a relationship with the Hungarian Central Bank and proposed that Wise join their payment scheme directly, an opportunity typically reserved for banks. My argument was simple: it would make transfers cheaper for Hungarians. Three months after launch, I called the bank to share the results—we had reduced prices by 20%.
            </p>
            <p>
                By 2020, I was leading operations across Europe and the U.S., managing seven teams responsible for systems that generated 70% of Wise’s revenue.
            </p>
            <h2>Building Wise’s U.S. Hub</h2>
            <p>
                In 2022, I embraced the challenge of building Wise’s U.S. headquarters in Austin, Texas, as the first employee in the city. My wife, Orsolya—who is also a software engineer and a world championship bronze medalist kickboxer—and I packed our lives, including our two-year-old son, and boarded one of the last flights during the COVID pandemic. Yes, it’s a fun family.
            </p>
            <p>
                Today, Austin is home, and our family has grown to include our five-year-old son Balázs Jr. and five-month-old Benjámin.
            </p>
            <p>
                Starting from scratch, I helped scale the Austin office to 400 employees in less than three years, making it Wise’s largest U.S. site. As the leader of U.S. engineering, I’m exclusively focused on the U.S. market, which has experienced rapid growth over the last four years. My teams cover payment integrations, the stored value product, and the U.S. onboarding experience.
            </p>
            <p>
                One of my favorite projects was designing and launching the U.S. interest feature. This product lets customers earn interest on their balances while retaining full access to their funds—a concept we later expanded to GBP and EUR balances.
            </p>
            <h2>Philosophy on Building</h2>
            <p>
                I’m pragmatic in my approach to building systems and solving problems. If a decision is easily reversible, I believe in releasing quickly and letting customer feedback guide us—there’s no point in wasting time on endless debates. For me, the customer promise is everything, and delivering on that promise builds the trust that underpins long-term success.
            </p>
            <p>
                Technology remains one of the last fields where individuals truly own the tools of production. My mission is to build solutions that create real impact, lowering costs for customers and making essential services more accessible.
            </p>
            <p>
                I believe in building strong teams by hiring exceptionally smart, driven people who are willing to go the extra mile. Together, we create systems that deliver real value to customers. I prioritize quick, data-driven decision-making and focus on delivering results.
            </p>
            <h2>On a Personal Note</h2>
            <p>
                When I’m not working, I love spending time with my family, kickboxing, and grilling a great steak.
            </p>
            <p>
                I always enjoy connecting with people who share the same passions as I do. Let’s talk about fintech, technology, or whatever drives you!
            </p>
        </div>
    );
};

export default Blog;
